import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['description', 'checkbox', 'button', 'spanContainer', 'spanOn', 'spanOff'];

  connect() {  }

  toggle(event) {
    this.checkboxTarget.checked  = !this.checkboxTarget.checked;
    if (this.checkboxTarget.checked) {
      this.descriptionTarget.textContent = "Cancel: On"
      document.getElementById('edit_event_info').classList.add("hidden");

      this.buttonTarget.classList.add('bg-dw-primary-blue');

      this.spanContainerTarget.classList.remove('translate-x-0', 'bg-dw-primary-blue');
      this.spanContainerTarget.classList.add('translate-x-5');

      this.spanOnTarget.classList.add('opacity-0', 'ease-out', 'duration-100');
      this.spanOnTarget.classList.remove('opacity-100', 'ease-in', 'duration-200');

      this.spanOffTarget.classList.add('opacity-100', 'ease-in', 'duration-200');
      this.spanOffTarget.classList.remove('opacity-0', 'ease-out', 'duration-100');
    } else {

      this.descriptionTarget.textContent = "Cancel: Off"
      document.getElementById('edit_event_info').classList.remove("hidden");
      this.buttonTarget.classList.remove('bg-dw-primary-blue');

      this.spanContainerTarget.classList.add('translate-x-0');
      this.spanContainerTarget.classList.remove('translate-x-5');
      this.spanContainerTarget.classList.add('bg-dw-primary-blue');

      this.spanOnTarget.classList.remove('opacity-0', 'ease-out', 'duration-100');
      this.spanOnTarget.classList.add('opacity-100', 'ease-in', 'duration-200');

      this.spanOffTarget.classList.add('opacity-0', 'ease-out', 'duration-100');
      this.spanOffTarget.classList.remove('opacity-100', 'ease-in', 'duration-200');
    }
  }
}
