import { Controller } from "stimulus";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);
export default class extends Controller {
  static targets = ["date", "timezone", "startTime", "endTime"];

  close(event) {
    ["dateTarget", "timezoneTarget", "startTimeTarget", "endTimeTarget"].forEach(attribute => {
      this[attribute].nextElementSibling.classList.add('hidden');
      this[attribute].classList.remove('border-dw-primary-blue');
    });
  }

  selectDate(event) {
    event.currentTarget.nextElementSibling.classList.toggle('hidden');
    event.currentTarget.classList.toggle('border-dw-primary-blue');
    event.stopPropagation();
  }

  dateSelected(event) {
    const date_value = event.currentTarget.dataset.value;
    if (date_value) {
      const dayjsTime = dayjs.unix(parseInt(date_value)).utcOffset(parseInt(this.timezoneTarget.dataset.utcOffset));
      this.dateTarget.innerHTML = dayjsTime.format('dddd, MMM DD');
      document.getElementById('event_starts_at_date').value = dayjsTime.format('dddd, MMM DD');

      Array.from(event.currentTarget.parentElement.children)
           .forEach( e => e.classList.remove('text-dw-primary-blue') );
      event.currentTarget.classList.add("text-dw-primary-blue");
    } else {
      event.stopPropagation();
    }
  }

  selectTimezone(event) {
    event.currentTarget.nextElementSibling.classList.toggle('hidden');
    event.currentTarget.classList.toggle('border-dw-primary-blue');
    event.stopPropagation();
  }

  timezoneSelected(event) {
    const newUtcOffset = event.currentTarget.dataset.utcOffset;
    const oldUtcOffset = this.timezoneTarget.dataset.utcOffset;

    if (newUtcOffset != oldUtcOffset) {
      this.timezoneTarget.innerHTML = event.currentTarget.textContent;
      this.timezoneTarget.dataset.utcOffset = newUtcOffset;
      document.getElementById('event_timezone').value = event.currentTarget.textContent;

      Array.from(event.currentTarget.parentElement.children)
           .forEach( e => e.classList.remove('text-dw-primary-blue') );
      event.currentTarget.classList.add("text-dw-primary-blue");

      ["startTimeTarget", "endTimeTarget"].forEach(target => {
        let timeValue = this[target].dataset.value;
        let dayjsTime = dayjs.unix(timeValue).utcOffset(parseInt(newUtcOffset))
        this[target].innerHTML = dayjsTime.format('hh:mm A');
        document.getElementById(this[target].nextElementSibling.children[0].dataset.label).value = dayjsTime.format('hh:mm A');

        let startOfDayUnix = dayjsTime.startOf('day').unix();
        Array.from(this[target].nextElementSibling.children)
             .forEach( (e, i) => {
                e.dataset.value = startOfDayUnix + i * 15 * 60
                e.innerHTML = dayjs.unix(e.dataset.value).utcOffset(parseInt(newUtcOffset)).format('hh:mm A');
                e.classList.remove('text-dw-primary-blue')
                if (e.dataset.value === timeValue) {
                  e.classList.add('text-dw-primary-blue');
                }
              });
      })
    }
  }

  selectStartTime(event) {
    event.currentTarget.nextElementSibling.classList.toggle('hidden');
    event.currentTarget.classList.toggle('border-dw-primary-blue');
    event.stopPropagation();
  }

  startTimeSelected(event) {
    const timeValue = event.currentTarget.dataset.value;
    const dayjsTime = dayjs.unix(parseInt(timeValue)).utcOffset(parseInt(this.timezoneTarget.dataset.utcOffset));
    this.startTimeTarget.innerHTML = dayjsTime.format('hh:mm A');
    this.startTimeTarget.dataset.value = timeValue;
    document.getElementById(event.currentTarget.dataset.label).value = dayjsTime.format('hh:mm A');

    Array.from(event.currentTarget.parentElement.children)
         .forEach( e => e.classList.remove('text-dw-primary-blue') );
    event.currentTarget.classList.add("text-dw-primary-blue");
  }

  selectEndTime(event) {
    event.currentTarget.nextElementSibling.classList.toggle('hidden');
    event.currentTarget.classList.toggle('border-dw-primary-blue');
    event.stopPropagation();
  }

  endTimeSelected(event) {
    const timeValue = event.currentTarget.dataset.value;
    const dayjsTime = dayjs.unix(parseInt(timeValue)).utcOffset(parseInt(this.timezoneTarget.dataset.utcOffset));
    this.endTimeTarget.innerHTML = dayjsTime.format('hh:mm A');
    this.endTimeTarget.dataset.value = timeValue;
    document.getElementById(event.currentTarget.dataset.label).value = dayjsTime.format('hh:mm A');

    Array.from(event.currentTarget.parentElement.children)
         .forEach( e => e.classList.remove('text-dw-primary-blue') );
    event.currentTarget.classList.add("text-dw-primary-blue");
  }
}
