import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    "instructorPlaceholder",
    "liveClassPlaceholder"
  ]

  beforeReflex(element, reflex) {
    switch(reflex) {
      case "StudioInstructorSelectorReflex#select_studio":
        this.instructorPlaceholderTarget.disabled = true;
        break;
      case "StudioInstructorSelectorReflex#select_instructor":
        this.instructorPlaceholderTarget.disabled = true;
        this.liveClassPlaceholderTarget.disabled = true;
        break;
      case "StudioInstructorSelectorReflex#select_live_class":
        this.instructorPlaceholderTarget.disabled = true;
        this.liveClassPlaceholderTarget.disabled = true;
        break;
    }
  }
}
