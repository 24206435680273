import { Controller } from "stimulus";
import * as dayjs from "dayjs"

export default class extends Controller {
  static targets = ['modal', 'editContent', 'reviewContent', 'modalContent',
            'date', 'time', 'substitute', 'timezone'];

  editSchedule(event) {
    fetch(event.currentTarget.dataset.path)
    .then((response) => response.text())
    .then(data => this.modalContentTarget.innerHTML = data)

    this.modalTarget.classList.remove('hidden');
  }

  editBasicInfo(event) {
    fetch(event.currentTarget.dataset.path)
    .then((response) => response.text())
    .then(data => this.modalContentTarget.innerHTML = data)

    this.modalTarget.classList.remove('hidden');
  }

  reviewSuccess(event) {
    const [_data, _status, xhr] = event.detail

    this.modalContentTarget.innerHTML = xhr.response
  }

  reviewFailed(event) {  }


  close(event) {
    this.modalTarget.classList.add('hidden');
    this.modalContentTarget.innerHTML = "";
  }

  backToEditSuccess(event) {
    const [_data, _status, xhr] = event.detail

    this.modalContentTarget.innerHTML = xhr.response
  }
  backToEditFailed(event) {
  }

  backToEdit(event) {
    this.reviewContentTarget.classList.add('hidden');
    this.editContentTarget.classList.remove('hidden');
  }
}
