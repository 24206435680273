import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "source",
    "copied"
  ];

  copy(event) {
    event.preventDefault();
    this.sourceTarget.select()
    this.copiedTarget.hidden = false
    document.execCommand("copy")
  }
}
